var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CommanderSearch"},[_c('Autocomplete',{ref:"autocomplete",staticClass:"Input InputText",attrs:{"search":_vm.search,"defaultValue":_vm.defaultValue,"get-result-value":_vm.getResultValue,"autoSelect":"","debounceTime":200},on:{"submit":_vm.callback},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var rootProps = ref.rootProps;
        var inputProps = ref.inputProps;
        var inputListeners = ref.inputListeners;
        var handleBlur = ref.handleBlur;
        var focused = ref.focused;
        var resultListProps = ref.resultListProps;
        var resultListListeners = ref.resultListListeners;
        var results = ref.results;
        var resultProps = ref.resultProps;
return [_c('span',{staticClass:"icon icon--decorative"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'helmet-battle']}})],1),(_vm.isSearching)?_c('font-awesome-icon',{staticClass:"icon icon--status fa-spin commanderIcon",attrs:{"icon":['far', 'spinner']}}):_vm._e(),(_vm.isComplete && !_vm.isSearching)?_c('font-awesome-icon',{staticClass:"icon icon--status commanderIcon success",attrs:{"icon":['far', 'check']}}):_vm._e(),_c('div',_vm._b({staticClass:"InputText"},'div',rootProps,false),[_c('input',_vm._g(_vm._b({class:[
            'autocomplete-input',
            'input--text',
            'corners--rounded',
            { 'autocomplete-input-no-results': _vm.noResults },
            { 'autocomplete-input-focused': focused }
          ],attrs:{"placeholder":"Commander"},domProps:{"value":_vm.textValue},on:{"focus":_vm.handleFocus,"blur":handleBlur,"input":_vm.changeCallback,"keyup":_vm.enterCheck}},'input',inputProps,false),inputListeners)),_c('label',[_vm._v(" Commander Name ")]),_c('ul',_vm._g(_vm._b({},'ul',resultListProps,false),resultListListeners),_vm._l((results),function(result,index){return _c('li',_vm._b({key:resultProps[index].id,staticClass:"clickable"},'li',resultProps[index],false),[_vm._v(" "+_vm._s(result.name)+" ")])}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }