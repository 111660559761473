<template>
  <div class="ProfileCreator">
    <div class="settings">
      <label>
        <InputText
          ref="nameInput"
          iconClass="user"
          :inputData="playerName"
          @update="playerName = $event;"
          @validity="computeIsValid($event)"
          :default="playerName"
          minLength="3"
          maxLength="16"
          placeholder="Player Name"
          type="text"
          ></InputText>
      </label>
    </div>

    <div class="settings" v-if="isCommanderFormat()">
      <label>
        <CommanderSearch :submitCallback="pickCommanderA" :defaultValue="getCommander(0).name"></CommanderSearch>
      </label>
    </div>

    <div class="settings" v-if="isCommanderFormat() && usePartner">
      <label>
        <CommanderSearch :submitCallback="pickCommanderB" :defaultValue="getCommander(1).name"></CommanderSearch>
      </label>
    </div>

    <div class="settings" v-if="isCommanderFormat()">
      <InputCheckbox :default="getCommander(1).name" :inputData="usePartner" @update="usePartner = $event;" placeholder="Enable Partner"></InputCheckbox>
    </div>

    <h3>Avatar</h3>
    <div class="settings avatarSelect">
      <div class="avatar selected avatar--chosen" @click="expandAvatarList()">
        <ProfilePic :icon="playerAvatar" size="extra-large"></ProfilePic>
          <div class="inUseUser font-color--primary font-size--tiny" v-if="isAvatarInUse(playerAvatar)">
            <font-awesome-icon :icon="['far', 'user']" />
            &nbsp;
            <span>{{getAvatarInUseName(playerAvatar)}}</span>
          </div>
      </div>
      <Divider :clickCallback="expandAvatarList" text="More Avatars"></Divider>

      <div class="inputArea avatarList" :class="{expanded: isAvatarListExpanded}">
        <label class="avatar--label" v-for="item in $PLAYER_AVATAR_LIST" :key="item">
          <input
            v-model="playerAvatar"
            type="radio"
            name="playerAvatar"
            v-on:click="expandAvatarList"
            :value="item"/>
          <div class="avatar font-color--primary" :class="[{ selected: playerAvatar === item}, item]">
            <ProfilePic :icon="item" size="large" :class="{inUse: isAvatarInUse(item)}"></ProfilePic>
            <div class="inUseUser font-size--tiny" v-if="isAvatarInUse(item)">
              <font-awesome-icon :icon="['far', 'user']" />
              &nbsp;
              <span>{{getAvatarInUseName(item)}}</span>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>

  import ProfilePic from '@/components/ProfilePic.vue';
  import CommanderSearch from '@/components/CommanderSearch.vue';
  import InputCheckbox from '@/components/InputCheckbox.vue';
  import InputText from '@/components/InputText.vue';
  import Divider from '@/components/Divider.vue';

  export default {
    name: 'New',
    components: {
      ProfilePic,
      CommanderSearch,
      InputCheckbox,
      InputText,
      Divider
    },
    props: ['name', 'avatar'],
    data: (instance) => {
      return {
        playerName: instance.name ? instance.name : "",
        playerAvatar: instance.avatar ? instance.avatar : "ajani",
        commanderA: "",
        commanderB: "",
        usePartner: false,
        isAvatarListExpanded: false,
        isSending: false,
        isValid: false,
      }
    },

    methods: {

      computeIsValid(elementValidity) {
        const nameInput = this.$refs.nameInput;

        if(nameInput) {
          this.$emit('validity', elementValidity);
          this.isValid = elementValidity;
        } else {
          this.isValid = true;
          this.$emit('validity', elementValidity);
        }
      },

      expandAvatarList() {
        this.isAvatarListExpanded = !this.isAvatarListExpanded;
      },

      isCommanderFormat() {
        return ['commander', 'brawl'].includes(this.$store.state.game.game_obj.format)
      },

      isAvatarInUse(avatarName) {

        if(Object.keys(this.$store.state.game.game_obj.players).length > 0) {
          return Object.keys(this.$store.state.game.game_obj.players).filter(player => this.$store.state.game.game_obj.players[player].avatar === avatarName).length > 0;
        } else {
          return false;
        }

      },

      getAvatarInUseName(avatarName) {
        if(Object.keys(this.$store.state.game.game_obj.players).length > 0) {

          const avatarUser = Object.keys(this.$store.state.game.game_obj.players).filter(player => this.$store.state.game.game_obj.players[player].avatar === avatarName)[0];

          return this.$store.state.game.game_obj.players[avatarUser].name;
        } else {
          return '';
        }
      },

      getCommander(commanderNum) {
        if(this.$store.state.player.commanderList && this.$store.state.player.commanderList[commanderNum]) {
          return this.$store.state.player.commanderList[commanderNum];
        } else {
          return {
            id: '',
            name: '',
          }
        }
      },

      pickCommanderA(commander) {
        this.commanderA = commander.id;
      },

      pickCommanderB(commander) {
        this.commanderB = commander.id;
      },

      getFinalBody() {
        let commanderList = null;

        if(this.isCommanderFormat()) {
          commanderList = [this.commanderA];

          if(this.usePartner) {
            commanderList.push(this.commanderB);
          }
        }
        return {
          "name": this.playerName,
          "avatar": this.playerAvatar,
          "commanderList": commanderList,
          "publicId": localStorage.getItem('default--player_public'),
          "secretId": localStorage.getItem('default--player_secret')
        }
      }
    }
  }
</script>
<style scoped lang="less">
.inputArea {
  display: flex;
  flex-flow: row;
  justify-content: center;
  text-align: center;

  // Hides the secret radio buttons on the avatars
  input {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

.avatar {
  cursor: pointer;
}

.avatarList {
  flex-wrap: wrap;

  height: auto;
  overflow: hidden;
  transition: max-height .75s;
  box-sizing: border-box;
  padding: 15px 15px 20px 15px;
  max-height: 0;

  &.expanded {
    max-height: 600px;
  }
}


.avatar {

  &--chosen {
    margin: auto;
    height: 100px;
    width: 100px;
  }

  &--label {
    display: block;
  }

  height: 75px;
  width: 75px;
  padding: 5px;
  margin: 5px 5px 0 5px;
  box-sizing: content-box;
  border-radius: 55px;

  &.selected {
    background: @app-color-primary;
  }

  &:not(.selected) {
    .inUse {
      opacity: .25;
    }
  }

  .inUseUser {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }
}
</style>