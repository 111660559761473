<template>
  <div class="CommanderSearch">
    <Autocomplete
      ref="autocomplete"
      class="Input InputText"
      :search="search"
      :defaultValue="defaultValue"
      :get-result-value="getResultValue"
      autoSelect
      :debounceTime=200
      @submit="callback">
      <template
          #default="{
            rootProps,
            inputProps,
            inputListeners,
            handleBlur,
            focused,
            resultListProps,
            resultListListeners,
            results,
            resultProps
          }"
        >
        <span class="icon icon--decorative">
          <font-awesome-icon :icon="['far', 'helmet-battle']" />
        </span>
        <font-awesome-icon  v-if="isSearching" class="icon icon--status fa-spin commanderIcon" :icon="['far', 'spinner']"/>
        <font-awesome-icon class="icon icon--status commanderIcon success" v-if="isComplete && !isSearching" :icon="['far', 'check']" />
        <div class="InputText" v-bind="rootProps">
          <input
            v-bind="inputProps"
            v-on="inputListeners"
            :class="[
              'autocomplete-input',
              'input--text',
              'corners--rounded',
              { 'autocomplete-input-no-results': noResults },
              { 'autocomplete-input-focused': focused }
            ]"
            @focus="handleFocus"
            @blur="handleBlur"
            @input="changeCallback"
            @keyup="enterCheck"
            :value="textValue"
            placeholder="Commander"
          />
          <label>
            Commander Name
          </label>
          <ul v-bind="resultListProps" v-on="resultListListeners">
            <li
              v-for="(result, index) in results"
              :key="resultProps[index].id"
              class="clickable"
              v-bind="resultProps[index]">
              {{ result.name }}
            </li>
          </ul>
        </div>
      </template>
    </Autocomplete>
  </div>
</template>
<script>
  // https://www.npmjs.com/package/@trevoreyre/autocomplete-vue
  import Autocomplete from '@trevoreyre/autocomplete-vue';
  //import InputText from '../components/InputText.vue';
  const axios = require('axios');
  export default {
    components: {
      Autocomplete,
      //InputText
    },
    name: 'CommanderSearch',
    props: ['submitCallback', 'defaultValue'],
    data: () => {
      return {
        noResults: false,
        isSearching: false,
        isComplete: false,
        textValue: '',
      }
    },
    created: async function () {
      // Validate the default value, if we have one
      if(this.defaultValue) {
        const searchResults = await this.search(this.defaultValue);

        if(searchResults[0] && searchResults[0].name === this.defaultValue) {
          this.callback(searchResults[0]);
        } else {
          this.submitCallback({
            id: this.defaultValue,
            name: this.defaultValue
          });
        }
      }
    },
    methods: {
      async search(input) {
        if(input.length < 3) {
          return [];
        }

        this.noResults = false;
        this.isSearching = true;
        this.isComplete = false;
        try {
          let ret = await axios.get(`https://api.scryfall.com/cards/search?q=${input} is:commander format:commander`);
          this.searchResults = ret.data.data;
          return this.searchResults;
        } catch(e) {
          if(e.response.status === 404) {
            this.noResults = true;
            return [];
          }
        } finally {
          this.isSearching = false;
        }
        
      },

      getResultValue(card) {
        return card.name;
      },

      changeCallback(e) {
        this.textValue = e.target.value;
        this.isComplete = false;
        this.submitCallback({
          name: e.target.value,
          id: e.target.value
        });
      },

      enterCheck(e) {
        if(e.keyCode === 13) {
          e.target.blur();
        }
      },

      handleFocus() {
        this.$refs.autocomplete.value = '';
        //console.log(this.$refs.autocomplete);
        this.textValue = '';
        this.submitCallback({
          id: '',
          name: ''
        });
      },

      callback(result) {

        if(result?.id) {
          this.textValue = result.name;
          this.isComplete = true;
          this.submitCallback(result);
        }
      },
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.CommanderSearch {

  position: relative;
  width: 250px;
  margin: auto;
  margin-bottom: 30px;

  box-sizing: border-box;

  .autocomplete-result-list {
    margin-top: 0;
    list-style-type:none;
    padding: 0;
    width: 250px;
    z-index: 2 !important;
  }

  .autocomplete-result {
    box-sizing: border-box;
    width: 250px;
    background: #121212;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: .8em;
    
    text-align: left;

    &:hover.clickable {
      cursor: pointer;
      background: #242424;
    }
  }

  .icon--status {
    opacity: 1 !important;
    // This caused build problems
    //transform: rotate3d(1);
    transition: transform .5s;

    &.fa-spin {
      height: 20px !important;
      width: 20px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.success {
      color: green;
    }
  }
  
  ul {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 5px 5px;

    .fancyScrollbar();

    li {
      height: 40px;
    }
  }
}

</style>
