<template>
  <div class="Divider" v-bind:class="{empty: !text}" v-on:click="clickCallback ? clickCallback() : () => {}">
    <span class="inner">{{text}}</span>
  </div>
</template>

<script>
export default {
  name: 'Divider',
  props: ['clickCallback', 'text'],
  data: () => {
    return {
    }
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.Divider {

  @divider-style: 1px solid black;
  padding-top: 15px;
  cursor: pointer;
  
  height: 20px;
  width: 100%;
  border-bottom: @divider-style;
  text-align: center;

  &.empty {
    border-top: @divider-style;
    border-bottom: none;
    height: 0;    
  }

  &:hover {
    .inner {
      color: @font-color-primary;
    }
  }

  .inner {
    top: 12px;
    position: relative;
    padding: 0 10px;
    color: @font-color-secondary;
    background: @background-color-secondary;

    &:hover {
      color: @font-color-primary;
    }
  }
}
</style>
